.e-schedule .e-vertical-view .e-day-wrapper .e-appointment,
.e-schedule .e-month-view .e-appointment {
  @apply bg-primary !important;
}

.e-schedule .e-agenda-view .e-appointment,
.e-quick-popup-wrapper
  .e-event-popup
  .e-popup-header
  .e-subject-wrap
  .e-subject {
  @apply border-primary !important;
}

.e-quick-popup-wrapper
  .e-event-popup
  .e-popup-header
  .e-subject-wrap
  .e-subject {
  @apply bg-blue-100 !important;
}

.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons,
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-schedule .e-vertical-view .e-header-cells.e-current-day,
.e-schedule .e-month-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-timeline-view .e-date-header-wrap table td.e-current-day,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
  @apply text-primary !important;
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  @apply border-primary bg-primary text-white !important;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day,
.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day,
.e-input-focus {
  @apply border-primary !important;
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small
  .e-calendar
  .e-content
  td.e-selected.e-focused-date
  span.e-day,
.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  @apply bg-primary !important;
}

.e-btn.e-flat.e-primary:focus,
.e-css.e-btn.e-flat.e-primary:focus {
  @apply border-primary bg-primary text-white shadow-primary !important;
}

/* .e-all-day-time-zone-row,
.e-time-zone-row,
.e-repeat-parent-row,
.e-control .e-recurrenceeditor .e-lib {
  @apply hidden !important;
} */
.e-schedule-dialog .e-all-day-time-zone-row,
.e-recurrenceeditor .e-editor div:not(:first-child),
.e-location-container {
  @apply hidden !important;
}

.custom-element-container .e-input-group select {
  @apply p-0 !important;
}

.e-agenda-item {
  @apply bg-white !important;
}

.incomplete-task-blinking {
  @apply border-error bg-error;
  animation: incomplete-blinking 2s infinite ease-in-out;
}

/* The animation code */
@keyframes incomplete-blinking {
  0% {
    @apply bg-error;
  }
  50% {
    background: #ff7774;
  }
  100% {
    @apply bg-error;
  }
}

.pending-task-blinking {
  @apply border-cool-orange bg-cool-orange;
  animation: pending-blinking 2s infinite ease-in-out;
}

/* The animation code */
@keyframes pending-blinking {
  0% {
    @apply bg-cool-orange;
  }
  50% {
    background: #f08000;
  }
  100% {
    @apply bg-cool-orange;
  }
}
