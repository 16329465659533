#notice-editor-description_toolbar {
  @apply border-b-black/70 bg-white;
}

#notice-editor-description_toolbar * {
  @apply bg-white;
}

#notice-editor-descriptionrte-view {
  /* TOOLBAR HEIGHT = 38px */
  margin-top: 38px !important;
  height: calc(200px - 38px) !important;
}

#notice-editor-description_rte-edit-view {
  @apply px-5;
}

#notice-editor-descriptionrte-view .rte-placeholder {
  padding: 11px 12px 12px 13px;
  @apply px-5 !text-sm text-black/80;
}
