@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  @apply m-0 box-border p-0 outline-none;
}

* {
  font-family: "Roboto", "inter", sans-serif;
}

*::placeholder {
  @apply !text-sm !text-black/40;
}

h1 {
  font-size: 1.75em;
}

h2 {
  font-size: 1.25em;
}

h3 {
  font-size: 1.125em;
}

h4 {
  font-size: 1em;
}

p {
  font-size: 0.825em;
}

h5 {
  font-size: 0.75em;
}

h6 {
  font-size: 0.625em;
}

#root {
  @apply min-h-screen w-full overflow-x-hidden;
}

body {
  @apply min-h-screen w-full overflow-x-hidden;
}

main {
  min-height: calc(100vh - 4rem);
}

.title {
  @apply text-2xl font-semibold;
}

.input-label {
  @apply absolute left-4 top-0 -translate-y-1/2 bg-white px-1 text-xs text-black/40;
}

.button-default {
  @apply rounded-lg border border-primary py-2 px-4 text-center text-xs text-primary shadow-md hover:border-blue-600 hover:text-blue-600 disabled:border-black/50 disabled:text-black/50;
}

.btn-primary {
  @apply rounded-md bg-primary py-2 px-4 text-xs text-white shadow-md hover:bg-blue-500 disabled:cursor-not-allowed disabled:bg-black/50;
}

.btn-danger {
  @apply rounded-md bg-error py-2 px-4 text-xs text-white shadow-md hover:bg-red-600 disabled:cursor-not-allowed disabled:bg-black/50;
}

.btn-secondary {
  @apply rounded-md bg-black/70 py-2 px-4 text-xs text-white shadow-md hover:bg-black/80 disabled:cursor-not-allowed disabled:bg-black/50;
}

input:focus {
  border-color: currentColor !important;
  --tw-ring-color: inherit !important;
  --tw-ring-shadow: inherit !important;
}

.ellipsis {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}

.word-break {
  @apply break-all;
}

.multiline-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.custom-scrollbar {
  padding-right: 0.5rem;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.5rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 2rem;
}

.notice-card-description * {
  @apply !text-black/50;
}

/* .input-group input:focus ~ label {
  @apply text-primary;
} */
